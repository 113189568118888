body {
  background-color: #ede9d0;
  background-color: white;
  font-family: "Pretendard";
  font-weight: 500;
}

.div-month {
  margin-top: 100px;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div-title {
  margin-top: 100px;
  font-size: 22px;
  text-align: center;
  font-weight: 600;
}
.div-title-sub {
  width: 90vw;
  word-break: keep-all;
  margin-top: 20px;
  font-size: 15px;
  text-align: center;
  line-height: 150%;
}
.icon-left {
  z-index: 10;
}
.icon-right {
  z-index: 10;
}

/* @font-face {
  font-family: "Nh";
  src: url("./NanumSquareNeo-eHv.ttf");
}

@font-face {
  font-family: "Neb";
  src: url("./NanumSquareNeo-dEb.ttf");
} */
/* Main.js */ /*------------------------------*/
.div-main-header {
  width: 60vw;
  height: 60px;
  display: flex;
  position: absolute;
  top: 0;
  left: 50vw;
  font-size: 22px;
  transform: translateX(-50%);
  z-index: 500;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-main-section-01 {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}

.div-main-section-01-01-01 {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  align-items: center;
}
.div-main-section-01-01-02 {
  text-align: center;
  margin-top: 10px;
  font-size: 25px;
  line-height: 150%;
}
.div-main-section-01-02 {
  text-align: center;
  font-size: 15px;
  line-height: 150%;
}
.div-main-section-01-03 {
  text-align: center;
  font-size: 25px;
  line-height: 200%;
}

.div-main-section-01-01-01-schedule {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  width: 180px;
}

.img-main {
  width: 270px;
}
.img-main-rushclan {
  width: 120px;
}
.div-main-rushclan {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.icon-main-arrow-down1 {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translateX(-50%);
  animation: animate1 2s infinite;
  animation-delay: -0.2s;
}
.icon-main-arrow-down2 {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  transform: translate(-50%, -10px);
  animation: animate2 2s infinite;
}

@keyframes animate1 {
  0% {
    opacity: 0;
    transform: translate(-50%, 0px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
}
@keyframes animate2 {
  0% {
    opacity: 0;
    transform: translate(-50%, -5px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, 5px);
  }
}
.icon-main-arrow-down {
  position: absolute;
  bottom: 2.5vh;
  left: 50%;
  transform: translate(-50%, -50%);
}
.div-main-instagram-kakao {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.div-main-instagram-kakao img {
  margin: 0 5px 0 5px;
}
.div-main-footer {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 8px;
  /* font-family: "Neb"; */
  width: 90vw;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

/*------------------------------*/ /* Main.js */

/* Header.js */ /*------------------------------*/
.div-header {
  width: 90vw;
  height: 60px;
  display: flex;
  position: fixed;
  top: 0;
  left: 5%;
  z-index: 200;
  background-color: white;
}
.div-header-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div-header-logo {
  font-size: 22px;
  font-weight: 700;
}
.div-header-menu {
  /* position: fixed;
  top: 0;
  left: 0; */
  background-color: #ede9d0;
  background-color: white;
  border-radius: 0 0 5px 5px;
  padding-top: 90px;
  padding-bottom: 40px;
  width: 100vw;
  z-index: 100;
  text-align: center;
  font-size: 18px;
}
.div-header-menu-not {
  height: 100vh;
}
.link-header {
  text-decoration: none;
  color: black;
}
.div-header-menu-wrap {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
}
.openAnimation0 {
  animation: open0 0.3s ease-out;
}
.closeAnimation0 {
  animation: close0 0.3s ease-out;
}
.openAnimation1 {
  animation: fadein 0.3s ease-out;
}
.closeAnimation1 {
  animation: fadeout 0.3s ease-out;
}
.openAnimation2 {
  animation: down 0.3s ease-out;
}
.closeAnimation2 {
  animation: up 0.3s ease-out;
}

@keyframes down {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes up {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes open0 {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
@keyframes close0 {
  from {
    transform: scaleY(1);
  }
  to {
    transform: scaleY(0);
  }
}
/*------------------------------*/ /* Header.js */

/* Profile.js */ /*------------------------------*/
.div-profile-profile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-family: "Nh"; */
  font-size: 20px;
}
.div-profile-date {
  margin-top: 30px;
  width: 30vw;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.div-profile-check-section {
  margin-top: 8px;
  background-color: #d2000f;
  display: flex;
  width: 20vw;
  padding: 10px 15px 10px 15px;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.img-profile-dongdle {
  width: 24vw;
  margin-top: 16px;
}
.div-profile-adfit-section {
  margin-top: 40px;
  width: 100vw;
}
.button-profile-check {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-profile-logout {
  position: absolute;
  bottom: 20px;
  font-size: 15px;
  margin-top: 10px;
}
/*------------------------------*/ /* Profile.js */

/* Ranking.js */ /*------------------------------*/
::-webkit-scrollbar {
  display: none;
}
.img-ranking {
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-ranking-squircle {
  width: 5vh;
  height: 5vh;
  object-fit: cover;
}
.div-ranking-img {
  width: 10vw;
  display: flex;
  justify-content: right;
}
.div-ranking-img-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25vw;
}
.div-ranking-name-font {
  font-size: 15px;
  width: 15vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-ranking-crown-name {
  font-size: 15px;
  width: 15vw;
  height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-ranking-crown {
  color: #e79b42;
  /* color: #d2000f; */
  font-size: 18px;
}

.div-ranking {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-ranking-section-01 {
  position: absolute;
  top: 150px;
  height: calc(100vh - 150px);
  overflow: scroll;
}
.div-ranking-section-02 {
  width: 80vw;
  height: 5vh;
  padding: 0 5vw 1vh 5vw;
  /* border-bottom: 1.5px solid rgba(0, 0, 0, 0.2); */
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  animation: ranking-down 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes ranking-down {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

/*------------------------------*/ /* Ranking.js */

/* Calendar.js */ /*------------------------------*/
.div-calendar-section {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-calendar {
  width: 80vw;
  font-size: 18px;
  text-align: center;
}
thead th {
  text-align: center;
  height: 4vh;
  background-color: #d2000f;
  border-radius: 5px;
}
th {
  width: 30vw;
  height: 10vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.calendar-t {
  background-color: #d2000f;
  border-radius: 5px;
}
.calendar-p {
  background-color: black;
  color: white;
  border-radius: 5px;
}
.calendar-f {
  background-color: white;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.div-calendar-schedule-section {
  text-align: center;
  width: 80vw;
  margin-top: 20px;
}
.div-calendar-schedule-01 {
  font-size: 20px;
  color: #d2000f;
  margin-top: 50px;
}
.div-calendar-schedule-02 {
  font-size: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.div-calendar-schedule-write {
  width: 80vw;
  padding: 5px 0;
  background-color: white;
  background-color: rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.input-calendar-schedule-write {
  width: 80%;
  border: none;
  font-size: 20px;
  text-align: center;
  /* font-family: "Neb"; */
  font-family: "Pretendard";
  background: none;
}
:focus {
  outline: none;
}

.input-ip {
  width: 80%;
  border: none;
  font-size: 20px;
  height: 40px;
  text-align: center;
  /* font-family: "Neb"; */
  font-family: "Pretendard";
}
:focus {
  outline: none;
}
.button-ip-current {
  margin-top: 12px;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  color: #d2000f;
}
.button-calendar-schedule-write {
  margin-top: 10px;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}

.button-calendar-schedule-add {
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
}
/*------------------------------*/ /* Calendar.js */

/* Attendance.js */ /*------------------------------*/
.div-attendance-section-01 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.div-attendance-no {
  position: absolute;
  top: 50vh;
  font-size: 25px;
  transform: translateY(-50%);
}
.div-manage-attendance-section-01 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.div-attendance-count {
  font-size: 20px;
  display: flex;
  justify-content: center;
}
.icon-attendance-info {
  width: 20px;
  height: 20px;
  margin: 5px;
}

.div-attendance-section-info {
  position: absolute;
  top: 50vh;
  transform: translate(0, -50%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 100;
  width: 120px;
  padding: 30px 30px 30px 30px;
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: white;
  color: white;
  color: black;
  border-radius: 5px;
}

.div-attendance-info {
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  /* font-family: "Neb"; */
  margin: 2px;
}

.div-attendance-info-01 {
  width: 20px;
  background-color: #d2000f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.div-attendance-info-02 {
  width: 20px;
  background-color: #d2000f;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}
.div-attendance-info-03 {
  width: 20px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.div-attendance-info-04 {
  width: 20px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d2000f;
}

/*------------------------------*/ /* Attendance.js */

.div-attendance-section {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
thead th {
  text-align: center;
  height: 4vh;
  background-color: #d2000f;
}
th {
  width: 30vw;
  height: 10vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.attendance-n {
  background-color: #d2000f;
}
.attendance-p {
  background-color: black;
  color: white;
}
.attendance-f {
  background-color: white;
  background-color: rgba(0, 0, 0, 0.05);
}

.attendance-0 {
  background-color: #d2000f;
  color: white;
}
.attendance-1 {
  background-color: #d2000f;
}
.attendance-2 {
  background-color: black;
  color: white;
}
.attendance-3 {
  background-color: black;
  color: #d2000f;
}

.div-attendance-piechart-01 {
  position: absolute;
  top: 30vh;
  width: 80vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-joindate-piechart {
  margin-top: 30px;
}
.div-attendance-piechart-02 {
  position: absolute;
  bottom: 100px;
  width: 80vw;
  height: 20vh;
  font-size: 25px;
  display: flex;
  /* background-color: pink; */
  justify-content: center;
  align-items: center;
}
.div-member-piechart-01 {
  width: 80vw;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-member-piechart-02 {
  position: absolute;
  bottom: 100px;
  width: 80vw;
  height: 30vh;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-member-all-month {
  font-size: 20px;
  text-align: center;
}

/* Regulations.js */ /*------------------------------*/
.div-regulations-section {
  position: absolute;
  top: 180px;
  width: 90vw;
  text-align: left;
  font-weight: 400;
}
.div-regulations-seal-section {
  text-align: right;
  position: relative;
}
.img-regulations-seal {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 50px;
}
/*------------------------------*/ /* Regulations.js */

/* Notice.js */ /*------------------------------*/
.div-notice-section-01 {
  position: absolute;
  top: 180px;
  display: flex;
  flex-direction: column;
}
.div-notice-section-02 {
  width: 80vw;
  height: 60px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5vw 20px 5vw;
  margin-bottom: 20px;
}
.div-notice-section-03 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #ede9d0;
  background-color: white;
  z-index: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-month-title {
  margin-top: 100px;
  font-size: 22px;
  padding: 0 5vw 0 5vw;
  font-weight: 600;
}
.icon-notice-close {
  width: 12px;
  height: 12px;
  background-color: #d2000f;
  padding: 5px;
  border-radius: 100%;
  margin-top: 10px;
}

.scrollLock {
  overflow: hidden;
}
.div-notice-title {
  width: 60vw;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.div-notice-time {
  text-align: right;
  width: 20vw;
  font-size: 10px;
}
.div-notice-content {
  margin-top: 20px;
  width: 80vw;
  height: 50vh;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05);

  font-size: 15px;
  padding: 0 5vw;
  border-radius: 5px;
  line-height: 150%;
  overflow-y: scroll;
}
.pre-notice-content {
  font-family: "Pretendard";
  white-space: pre-wrap;
  word-break: keep-all;
}
.div-ranking-count {
  width: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
}
.div-ranking-percent {
  font-size: 10px;
}
.button-notice-write {
  position: absolute;
  top: 105px;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-manage-attendance-write {
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.div-notice-write-section {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.input-notice-write-title {
  width: 80%;
  border: none;
  font-family: "Pretendard";
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05);

  border-radius: 5px;
  padding: 5px 0;
}
::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.div-absence-input {
  width: 70vw;
  height: 5vh;
  padding: 0 15vw 0 5vw;

  font-size: 15px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}
.icon-absence-close {
  position: absolute;
  margin-top: 5px;
  right: 5vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-absence-minus {
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 20px;
  border-radius: 100%;
}
.button-ip-minus {
  position: absolute;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 20px;
  border-radius: 100%;
}
.button-manage_attendance-minus {
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 20px;
  border-radius: 100%;
}
.div-absence-check {
  width: 90vw;
  display: flex;
  justify-content: center;
}
.input-absence-write-name {
  height: 6vh;
  width: 25vw;
  border: none;
  font-size: 20px;
  background: none;
  /* font-family: "Nh"; */
  font-family: "Pretendard";
}
::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.input-absence-write-pnum {
  border: none;
  font-size: 12px;
  background: none;
  /* font-family: "Nh"; */
  font-family: "Pretendard";
}
::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.input-absence-write-date {
  text-align: right;
  width: 30vw;
  border: none;
  font-size: 15px;
  background: none;
  /* font-family: "Nh"; */
  font-family: "Pretendard";
}
.input-ip-write-date {
  width: 40vw;
  border: none;
  font-size: 20px;
  background: none;
  /* font-family: "Nh"; */
  font-family: "Pretendard";
  text-align: right;
  text-align: center;
}
.textarea-notice-write-content {
  font-size: 20px;
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  /* font-family: "Neb"; */
  font-family: "Pretendard";
  line-height: 150%;
}
::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
.div-notice-header {
  width: 100vw;
  height: 60px;
  background-color: #ede9d0;
  background-color: white;
  position: fixed;
  top: 0;
  z-index: 10;
}
/*------------------------------*/ /* Notice.js */

/* Technique.js */ /*------------------------------*/
::-webkit-scrollbar {
  display: none;
}
.button-technique-plus {
  position: absolute;
  top: 102px;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;

  animation: rotate-button 0.2s ease-in;
}
.button-technique-checked {
  position: absolute;
  top: 102px;
  left: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}

@keyframes rotate-button {
  from {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(0);
  }
}

.div-technique-section-01 {
  position: absolute;
  top: 200px;
}
.div-technique-section-02 {
  width: 80vw;
  height: 5vh;
  padding: 0 5vw 1vh 5vw;
  /* border-bottom: 1.5px solid rgba(0, 0, 0, 0.2); */

  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  animation: ranking-down 1s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

@keyframes ranking-down {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

.div-technique-write-section {
  background-color: rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  width: 80vw;
  height: 5vh;
  padding: 5px 5vw 5px 5vw;

  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadein 0.125s ease-out;
  border-radius: 5px;
}

.input-technique-write {
  width: 60vw;
  border: none;
  font-size: 18px;
  background: none;
  /* font-family: "Nh"; */
  font-family: "Pretendard";
  text-align: center;
}
.button-technique-add-check {
  position: absolute;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.05);
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-technique-remove-check {
  position: absolute;
  right: 5vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-technique-check {
  position: absolute;
  left: 5vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
/*------------------------------*/ /* Technique.js */

/* KakaoLogin.js */ /*------------------------------*/
.button-login-sms {
  font-size: 15px;
  background-color: #d2000f;
  width: 270px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-login-input-pnum {
  width: 270px;
  position: relative;
  margin-top: 40px;
}
.div-login-sms-not {
  font-size: 12px;
  color: #ae0000;
  margin-top: 10px;
}
.div-kakaologin-body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div-kakaologin-register-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  /* font-family: "Neb"; */
}

.div-kakaologin-register-wrapper input {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05);

  width: calc(100% - 20px);
  height: 1em;
  padding: 10px;
  resize: none;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  text-align: center;
  margin-top: 15px;
  position: relative;
  /* font-family: "Neb"; */
}
::placeholder {
  color: rgba(0, 0, 0, 0.2);
}
/*------------------------------*/ /* KakaoLogin.js */

.div-absence {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.div-profile-absence-section {
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
}
.div-profile-absence {
  /* color: #d2000f; */
  font-size: 20px;
  margin-bottom: 10px;
}
.div-profile-warning {
  color: #d2000f;
  font-size: 20px;
  margin-bottom: 30px;
}
.div-absence-section-01 {
  position: absolute;
  top: 180px;
  padding-bottom: 5vh;
}
.div-warning-refresh-section {
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 5px;
  margin-right: 10vw;
  font-size: 12px;
}
.div-warning-section-01 {
  position: absolute;
  top: 180px;
  padding-bottom: 5vh;
}
.div-warning-section-02 {
  width: 80vw;
  height: 5vh;
  padding: 0 5vw 0 5vw;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  font-size: 20px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
.div-warning-notday-01 {
  text-align: center;
  margin-bottom: 5px;
}
.div-warning-notday-02 {
  text-align: center;
  margin-bottom: 5px;
  font-size: 12px;
}
.div-manage-section {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.div-manage-grid-section {
  position: absolute;
  top: 100px;
  display: grid;
  grid: ". .";
  gap: 6px;
}
.div-manage-grid-item {
  width: calc(45vw - 3px);
  height: 18vh;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border-radius: 5px;
}
.div-absence-section-02 {
  width: 80vw;
  height: 7vh;
  padding: 10px 5vw 10px 5vw;
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.3); */
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}
.div-ip-section-02 {
  width: 90vw;
  height: 5vh;
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05); */
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.div-manage-attendance-section-02 {
  width: 70vw;
  height: 5vh;
  padding: 0 15vw 0 5vw;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  font-size: 20px;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.icon-back {
  position: absolute;
  left: 5vw;
  top: 96px;
  transform: translateY(50%);
  z-index: 20;
}

.icon-go {
  position: absolute;
  right: 5vw;
  top: 96px;
  transform: translateY(50%);
  z-index: 20;
}
.icon-pie {
  position: absolute;
  right: 11vw;
  top: 96px;
  transform: translateY(50%);
  z-index: 20;
}
.icon-calendar {
  position: absolute;
  right: 5vw;
  top: 96px;
  transform: translateY(50%);
  z-index: 20;
}
.button-absence-add {
  margin-top: 30px;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}

.div-member-section {
  width: 80vw;
  height: 6vh;
  padding: 10px 5vw 10px 5vw;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.div-joindate-section {
  width: 80vw;
  padding: 20px 5vw 20px 5vw;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
}
.div-manage-attendance-section {
  width: 70vw;
  height: 6vh;
  padding: 10px 5vw 10px 5vw;
  border-radius: 5px;
  /* background-color: rgba(255, 255, 255, 0.3); */
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.div-member-name {
  font-size: 20px;
  width: 25vw;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
}
.div-absence-name {
  font-size: 20px;
  margin-bottom: 10px;
}
.div-absence-pnum {
  font-size: 12px;
}

.div-member-pnum {
  font-size: 15px;
  width: 35vw;
}
.div-joindate-pnum {
  font-size: 15px;
  text-align: center;
}
.div-member-join {
  font-size: 15px;
  width: 20vw;
  text-align: right;
}
.div-absence-date {
  font-size: 15px;
  width: 30vw;
  display: flex;
  align-items: center;
  justify-content: right;
}

.div-member-updown-section {
  width: 80vw;
  margin: 0 5vw 0 5vw;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 25px;
}

.div-member-updown {
  width: 15vw;
  display: flex;
  justify-content: space-between;
}
.div-member-section-01 {
  position: absolute;
  top: 195px;
  padding-bottom: 5vh;
}

.icon-color {
  color: #d2000f;
}

.div-member-attendance-type-section {
  text-align: center;
  margin-top: 30px;
  width: 60vw;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
}
.div-member-attendance-type-updown {
  width: 12vw;
  padding: 1vw;
  background-color: white;
  background-color: rgba(0, 0, 0, 0.05);
}

.div-member-attendance-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: #ede9d0;
  background-color: white;
  z-index: 10;
}
.div-member-attendance-update-manager {
  position: fixed;
  bottom: 48px;
  left: 50%;
  transform: translateX(-50%);

  background-color: #d2000f;
  display: flex;
  padding: 10px 15px 10px 15px;
  border-radius: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.button-member-attendance-detail {
  margin-top: 20px;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}

.div-member-count {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
}

.button-member-plus {
  position: absolute;
  top: 102px;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-member-minus {
  position: absolute;
  top: 135px;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #d2000f;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}

.button-member-check {
  position: absolute;
  top: 140px;
  right: 10vw;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.icon-login-check {
  width: 12px;
  height: 12px;
  margin-top: 15px;
  color: #ae0000;
}
.button-login-check {
  margin-top: 50px;
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-manage-attendance-check {
  width: 12px;
  height: 12px;
  padding: 5px;
  background-color: #e79b42;
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
}
.button-member-remove-check {
  width: 12px;
  height: 12px;
  padding: 5px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  font-size: 15px;
  border-radius: 100%;
  flex-shrink: 0;
}

/*///////*/
.input-member-write-name {
  box-sizing: border-box;
  border: none;
  font-size: 20px;
  /* background: none; */
  font-family: "Pretendard";
  text-align: left;
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 15px 5vw;
  border-radius: 5px;
}

.input-member-write-pnum {
  box-sizing: border-box;
  border: none;
  font-size: 20px;
  /* background: none; */
  font-family: "Pretendard";
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 15px 5vw;
  border-radius: 5px;
}

.input-member-write-joindate {
  box-sizing: border-box;
  border: none;
  font-size: 20px;
  /* background: none; */
  font-family: "Pretendard";
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 15px 5vw;
  border-radius: 5px;
}
.squircle-profile {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-profile-squircle {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.div-modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div-modal {
  padding: 20px 15px 10px 15px;
  background-color: white;
  text-align: center;
}
.div-samename-section {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}
.div-samename-name {
  font-size: 20px;
}
.div-samename-pnum {
  font-size: 20px;
}
.div-samename-button-section {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5px;
}
.div-samename-button-cancel {
  padding: 5px 10px;
  font-family: "Pretendard";
  border: none;
  color: white;
  background-color: black;
}
.div-samename-button-check {
  padding: 5px 10px;
  font-family: "Pretendard";
  border: none;
  color: white;
  background-color: #d2000f;
}

/* 겨울(눈) */
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 0.2px #000;
}

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@-webkit-keyframes snowflakes-shake {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px);
  }
  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}
@keyframes snowflakes-fall {
  0% {
    top: -10%;
  }
  100% {
    top: 100%;
  }
}
@keyframes snowflakes-shake {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(80px);
  }
  100% {
    transform: translateX(0px);
  }
}
.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall, snowflakes-shake;
  -webkit-animation-duration: 10s, 3s;
  -webkit-animation-timing-function: linear, ease-in-out;
  -webkit-animation-iteration-count: infinite, infinite;
  -webkit-animation-play-state: running, running;
  animation-name: snowflakes-fall, snowflakes-shake;
  animation-duration: 10s, 3s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s, 0s;
  animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s, 1s;
  animation-delay: 1s, 1s;
  font-size: 1.2em;
}
.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s, 0.5s;
  animation-delay: 6s, 0.5s;
  font-size: 0.9em;
}
.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s, 2s;
  animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s, 2s;
  animation-delay: 2s, 2s;
  font-size: 1.15em;
}
.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s, 3s;
  animation-delay: 8s, 3s;
  font-size: 0.95em;
}
.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s, 2s;
  animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s, 1s;
  animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s, 0s;
  animation-delay: 1s, 0s;
  font-size: 1.1em;
}
.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s, 1.5s;
  animation-delay: 3s, 1.5s;
}

/* 여름(물결) */
.circle {
  width: 150vw;
  height: 20px;
  position: fixed;
  bottom: 0;
  /* overflow: hidden; */
  /* background: linear-gradient(#3c5aff, #52f2fd); */
  z-index: 9999;
  /* transform: rotate(180deg); */
}

.wave-one {
  width: 150vw;
  height: 150vw;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 45%;
  background: rgba(214, 244, 255, 0.8);
  animation: move 7s infinite linear;
}

.wave-two {
  width: 150vw;
  height: 150vw;
  position: absolute;
  top: 0;
  left: -25%;
  border-radius: 45%;
  background: rgba(39, 164, 242, 0.8);
  animation: move 7s infinite linear;
}

.wave-three {
  width: 180vw;
  height: 180vw;
  position: absolute;
  top: 0;
  left: 10%;
  border-radius: 45%;
  background: rgba(0, 82, 181, 0.8);
  /* border: 6px solid rgba(131, 119, 152, 0.7); */
  animation: move 9s infinite linear;
}

.wave-four {
  width: 180vw;
  height: 180vw;
  position: absolute;
  top: 0;
  left: -50%;
  border-radius: 45%;
  background: rgba(0, 82, 181, 0.8);
  animation: move 9s infinite linear;
}

@keyframes move {
  100% {
    transform: rotate(360deg);
  }
}
